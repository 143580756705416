import { GetStaticProps } from 'next';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import {
  ComponentPropsContext,
  SitecoreContext,
  handleEditorFastRefresh,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import { resolveLayout } from 'src/layout-factory';
import { useEffect } from 'react';
import { componentBuilder } from 'temp/componentBuilder';

const Custom404 = ({ componentProps, layoutData }: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  const isEditing = layoutData.sitecore.context.pageEditing;
  const Layout = layoutData.sitecore.route && resolveLayout(layoutData.sitecore?.route);

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        <Layout layoutData={layoutData} />
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  if (context) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = { path: ['not-found'] };
    context.params = params;
  }

  const props = await sitecorePagePropsFactory.create(context);

  // Check if we have a redirect (e.g. custom error page)
  if (props.redirect) {
    return {
      redirect: props.redirect,
    };
  }

  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 24 hours
    revalidate: 86400, // In seconds
  };
};

export default Custom404;
